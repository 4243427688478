<template>
  <DefaultSelect
    v-bind="$attrs"
    label="Która ciąża"
    placeholder="Kliknij by wybrać..."
    :value.sync="selectedProxy"
    :items="items"
    return-object
    hide-details
    noMargins
  >
  </DefaultSelect>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      items: [
        { text: "1 - Pierwsza", value: 1 },
        { text: "2 - Druga", value: 2 },
        { text: "3 - Trzecia", value: 3 },
        { text: "4 - Czwarta", value: 4 },
        { text: "5 - Piąta", value: 5 },
        { text: "6 - Szósta", value: 6 },
        { text: "7 - Siódma", value: 7 },
        { text: "8 - Ósma", value: 8 },
        { text: "9 - Dziewiąta", value: 9 },
        { text: "10 - Dziesiąta", value: 10 },
        { text: "11 - Jedenasta", value: 11 },
        { text: "12 - Dwunasta", value: 12 },
        { text: "13 - Trzynasta", value: 13 },
        { text: "14 - Czternasta", value: 14 },
        { text: "15 - Piętnasta", value: 15 },
        { text: "16 - Szesnasta", value: 16 },
        { text: "17 - Siedemnasta", value: 17 },
        { text: "18 - Osiemnasta", value: 18 },
        { text: "19 - Dziewiętnasta", value: 19 },
        { text: "20 - Dwudziesta", value: 20 },
      ],
    };
  },
  computed: {
    selectedProxy: {
      get() {
        const index = _.findIndex(this.items, { value: this.value });
        if (index !== -1) {
          return this.items[index];
        } else {
          return this.items[0];
        }
      },
      set(item) {
        this.$emit("input", item.value);
      },
    },
  },
  components: {
    DefaultSelect: () => import("@/components/selects/DefaultSelect"),
  },
};
</script>